import $ from "jquery";
import { isCompExists, logFactory, components } from "GlobalSite";

const compName = "comp_aem_layout_contact";
const Log = logFactory(compName);
const { log } = Log;

const {
  compTechGenericModal: { openDialog },
} = components;

log("--->");

$(function domReady() {
  if (!isCompExists(compName, Log)) {
    return;
  }

  const $contactElement = $(".contact-nav");
  const $toggleButton = $(".sticky-toggle-button");
  const $body = $("body");
  const $document = $(document);
  const $contactDialog = $("#contactDialog");
  let isExpanded = false;

  if ($contactElement.length && $toggleButton.length) {
    // Seuil de défilement basé sur la hauteur du contact-nav
    const SCROLL_THRESHOLD = $contactElement.outerHeight() || 100;

    // Créer une copie de l'élément pour le mode sticky
    const $stickyContact = $contactElement.clone(true);
    $stickyContact
      .addClass("sticky-contact sticky is-collapsed")
      .attr({
        id: "sticky-contact",
        role: "navigation",
        "aria-label": "Options de contact (menu flottant)",
      })
      .css({
        display: "block",
        visibility: "hidden",
        position: "absolute",
      });

    // Ajouter temporairement au DOM pour mesurer la hauteur
    $body.append($stickyContact);
    const contactHeight = $stickyContact.outerHeight();

    // Initialiser les styles du toggle button
    $toggleButton.css({
      height: `${contactHeight}px`,
      width: `${contactHeight}px`,
      display: "none",
    });

    // Remettre le sticky en position normale
    $stickyContact.css({
      display: "none",
      visibility: "visible",
      position: "fixed",
    });

    // Ajouter le bouton de fermeture
    const $closeButton = $("<button>", {
      class: "sticky-close-button",
      type: "button",
      "aria-label": "Fermer le menu de contact",
      html: `<i class="hbi-cross" aria-hidden="true"></i>`,
    });

    // Ajouter le bouton de fermeture au contact sticky
    $stickyContact.prepend($closeButton);
    $body.append($stickyContact);

    // Gérer les événements
    $toggleButton.on("click", () => {
      isExpanded = true;
      $toggleButton.attr("aria-expanded", "true");
      $stickyContact.css("display", "block");
      setTimeout(() => {
        $stickyContact.removeClass("is-collapsed");
        $toggleButton.css("opacity", "0").show().animate({ opacity: 1 }, 300);
        // Focus sur le premier élément interactif
        $stickyContact.find("a, button").first().focus();
      }, 50);
    });

    $closeButton.on("click", () => {
      isExpanded = false;
      $toggleButton.attr("aria-expanded", "false");
      $stickyContact.addClass("is-collapsed");
      setTimeout(() => {
        $toggleButton.css("opacity", "0").show().animate({ opacity: 1 }, 300);
        // Retour du focus au bouton toggle
        $toggleButton.focus();
      }, 300);
    });

    // Gestion de la touche Echap
    $(document).on("keydown", (e) => {
      if (e.key === "Escape" && isExpanded) {
        $closeButton.click();
      }
    });

    // Piège à focus pour le menu sticky
    $stickyContact.on("keydown", (e) => {
      if (e.key === "Tab") {
        const $focusableElements = $stickyContact.find("a, button");
        const $firstFocusable = $focusableElements.first();
        const $lastFocusable = $focusableElements.last();

        if (e.shiftKey) {
          if (document.activeElement === $firstFocusable[0]) {
            e.preventDefault();
            $lastFocusable.focus();
          }
        } else {
          if (document.activeElement === $lastFocusable[0]) {
            e.preventDefault();
            $firstFocusable.focus();
          }
        }
      }
    });

    // Observer pour le sticky
    const observer = new IntersectionObserver(
      (entries) => {
        // Récupération de l'entrée d'observation
        const entry = entries[0];
        // Vérification si c'est le chargement initial de la page (pas caché et en haut de page)
        const isInitialLoad = !document.hidden && window.scrollY === 0;

        // Afficher le bouton si l'élément n'est pas visible ET
        // soit c'est le chargement initial, soit on a scrollé plus loin que la hauteur du contact
        if (
          !entry.isIntersecting &&
          (isInitialLoad || $(window).scrollTop() > SCROLL_THRESHOLD)
        ) {
          if (isExpanded) {
            // Si c'était déplié, on le montre déplié
            $stickyContact.removeClass("is-collapsed").css("display", "block");
            $toggleButton.hide();
          } else {
            // Si c'était replié, on montre le bouton toggle
            $stickyContact.addClass("is-collapsed");
            $toggleButton
              .css("opacity", "0")
              .show()
              .animate({ opacity: 1 }, 300);
          }
        } else {
          // On cache le sticky immédiatement si il était déployé
          if (isExpanded) {
            $stickyContact.css({
              display: "none",
              transition: "none",
            });
            $toggleButton.hide();
            // Réactiver la transition après le changement
            setTimeout(() => {
              $stickyContact.css("transition", "");
            }, 50);
          } else {
            // Animation normale si il était replié
            $toggleButton.animate({ opacity: 0 }, 300, function () {
              $(this).hide();
            });
            $stickyContact.addClass("is-collapsed");
            setTimeout(() => {
              $stickyContact.css("display", "none");
            }, 300);
          }
        }
      },
      {
        threshold: [0],
        rootMargin: "-1px 0px 0px 0px",
      },
    );

    observer.observe($contactElement[0]);
  }

  $document.on("click", ".appelez-nous", function (event) {
    event.preventDefault();
    openDialog($contactDialog);
  });
});
